import axios from "axios";
import authHeader from "./auth-header";

let API_URL = "https://api.amarant.fi/api/";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  API_URL = "http://localhost:8080/api/";
}

const uploadImages = (id, content) => {
  const headers = authHeader();
  return axios.post(API_URL + `galleries/albums/` + id, content, {
    headers: headers,
  });
};

const updateImages = (content) => {
  const headers = authHeader();
  return axios.put(API_URL + `galleries/photos/`, content, {
    headers: headers,
  });
};

const deleteImages = (id, content) => {
  const headers = authHeader();
  return axios.delete(API_URL + `galleries/albums/` + id, {
    headers: headers,
    data: content,
  });
};

const getAlbums = () => {
  return axios.get(API_URL + `galleries/albums`, {
    headers: authHeader(),
  });
};

const getAlbum = (albumId) => {
  return axios.get(API_URL + `galleries/albums/${albumId}`, {
    headers: authHeader(),
  });
};

const addAlbum = (content) => {
  return axios.post(API_URL + `galleries/albums/`, content, {
    headers: authHeader(),
  });
};

const updateAlbum = (album) => {
  return axios.put(API_URL + `galleries/albums/album.id`, album, {
    headers: authHeader(),
  });
};

const tmp = {
  uploadImages,
  getAlbum,
  getAlbums,
  addAlbum,
  deleteImages,
  updateImages,
  updateAlbum,
};
export default tmp;
