import React, { useState, Fragment } from "react";
import GalleryService from "../../services/gallery.service";
import { useTranslation } from "react-i18next";
import { Button, Input } from "../Common/CommonComponents";
import { useHistory } from "react-router-dom";
const AddAlbum = () => {
  const [newAlbum, setNewAlbum] = useState({
    date: "",
    name: "",
  });
  const history = useHistory();
  const [value, setValue] = useState(null);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const save = () => {
    GalleryService.addAlbum(newAlbum).then(
      (response) => {
        if (response.data && response.data) {
          history.push("/gallery");
        } else {
          setError(t("Lagring misslyckades"));
          setValue(null);
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(_content);
        setValue(true);
      }
    );
  };

  const handleChange = (e) => {
    setNewAlbum({ ...newAlbum, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      <Input
        placeholder={t("Albumnamn")}
        name="name"
        value={newAlbum.name}
        label={t("Namn")}
        id="name"
        onChange={handleChange}
      />

      <Input
        placeholder={t("Datum")}
        name="date"
        value={newAlbum.date}
        label={t("Datum")}
        id="date"
        type="date"
        onChange={handleChange}
      />

      <Button text={t("Spara")} onClick={save}></Button>
    </Fragment>
  );
};

export default AddAlbum;
