import React from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Pages/Home";
import BoardTasks from "./components/Pages/BoardTasks";
import History from "./components/Pages/History";
import NewMembers from "./components/Pages/NewMembers";
import NextBal from "./components/Pages/NextBal";
import Profile from "./components/Profile";
import Grades from "./components/Pages/Grades";
// import BoardModerator from "./components/BoardModerator";
import UserList from "./components/UserList";
import Gallery from "./components/Gallery/Gallery";
import AddAlbum from "./components/Gallery/AddAlbum";
import Album from "./components/Gallery/Album";
import Nav from "./components/Nav";
import UpdatePassword from './components/Auth/UpdatePassword'
import ForgotPassword from './components/Auth/ForgotPassword'

const App = () => {
  return (
    <div className="background">
      <Nav />

      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 p-2 h-screen ">
          {/* <!-- Replace with your content --> */}
          <div className="container mt-3">
            <Switch>
              <Route exact path={["/", "/home"]} component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/profile/:id?" component={Profile} />
              <Route exact path="/board" component={BoardTasks} />
              <Route exact path="/grades" component={Grades} />
              <Route exact path="/history" component={History} />
              <Route exact path="/new-members" component={NewMembers} />
              <Route exact path="/next-bal" component={NextBal} />
              {/* <Route path="/mod" component={BoardModerator} /> */}
              <Route exact path="/users" component={UserList} />
              <Route exact path="/gallery" component={Gallery} />
              <Route exact path="/gallery/album/add" component={AddAlbum} />
              <Route exact path="/gallery/album/:id" component={Album} />
              <Route exact path="/password/reset/:userId/:token" component={UpdatePassword} />
              <Route exact path="/forgot-password/" component={ForgotPassword} />
            </Switch>
          </div>
          {/* <!-- /End replace --> */}
        </div>
      </main>
    </div>
  );
};

export default App;
