import axios from "axios";
import authHeader from "./auth-header";

let API_URL = "https://api.amarant.fi/api/";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  API_URL = "http://localhost:8080/api/";
}

const getAllUsers = () => {
  return axios.get(API_URL + "user/all", { headers: authHeader() });
};

const getAllRoles = () => {
  return axios.get(API_URL + "role/all", { headers: authHeader() });
};

const getAllGrades = () => {
  return axios.get(API_URL + "grade/all", { headers: authHeader() });
};

const updateUsers = (user) => {
  return axios.put(API_URL + "user/" + user._id, user, {
    headers: authHeader(),
  });
};

const getUser = (id) => {
  return axios.get(API_URL + `user/${id}`, {
    headers: authHeader(),
  });
};

const searchUsers = (name) => {
  return axios.get(API_URL + `user`, {
    params: {
      searchStrings: name.split(" ").filter((string) => {
        return string.length > 1;
      }),
    },
    headers: authHeader(),
  });
};

const deleteUser = (id) => {
  return axios.delete(API_URL + `user/${id}`, {
    headers: authHeader(),
  });
};

const tmp = {
  getAllUsers,
  updateUsers,
  getUser,
  getAllRoles,
  getAllGrades,
  deleteUser,
  searchUsers,
};
export default tmp;
