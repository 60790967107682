import Gallery from "react-grid-gallery";
import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
} from "react";
import { Button } from "../Common/CommonComponents";
import GalleryService from "../../services/gallery.service";
import AuthService from "../../services/auth.service";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const Album = () => {
  const { id } = useParams();
  const [album, setAlbum] = useState({});
  const [saved, setSaved] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length < 1) {
      return;
    }
    var formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("images", file);
    });
    GalleryService.uploadImages(id, formData).then(
      (response) => {
        if (response.data && response.data) {
          setAlbum(response.data);
          setSaved(true);
        } else {
          setError(t("Lagring misslyckades"));
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(_content);
      }
    );
  }, []);
  const {
    acceptedFiles,
    fileRejections,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: "image/*",
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    GalleryService.getAlbum(id).then(
      (response) => {
        if (response.data && response.data) {
          setAlbum(response.data);
        } else {
          setAlbum([]);
        }
      },
      () => {}
    );
  }, []);

  const handleImageSelect = (index, image) => {
    var images = album.images.slice();
    var img = images[index];
    if (img.hasOwnProperty("isSelected")) img.isSelected = !img.isSelected;
    else img.isSelected = true;
    setAlbum({ ...album });
  };

  const deleteImages = () => {
    if (
      window.confirm(t("Är du säker på att du vill radera de valda bilderna?"))
    ) {
      GalleryService.deleteImages(id, getSelectedImages()).then(
        (response) => {
          if (response.data && response.data) {
            setAlbum(response.data);
            setSaved(true);
          } else {
            setError(t("Borttagning misslyckades"));
          }
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setError(_content);
        }
      );
    }
  };

  const getImages = () => {
    if (album.images) {
      const items = album.images.map((image) => {
        return {
          ...image,
          thumbnailWidth: image.w,
          thumbnailHeight: image.h,
          thumbnail: image.thumbnail,
          caption:
            (image.comment ? image.comment : "") +
            " " +
            t("Uppladdad av") +
            ": " +
            image.user.name +
            " " +
            image.user.surname,
        };
      });

      return (
        <Gallery
          images={items}
          enableImageSelection={true}
          onSelectImage={handleImageSelect}
        />
      );
    } else {
      return <div />;
    }
  };

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  const saveImages = () => {
    GalleryService.updateImages(getSelectedImages());
    setEditMode(false);
  };

  const saveCoverPhoto = () => {
    album.coverPhoto = getSelectedImages()[0];
    GalleryService.updateAlbum(album);
  };

  const getSelectedImages = ()=>{ return album.images
    ? album.images.filter((image) => image.isSelected)
    : [];}
  return (
    <Fragment>
      {!editMode && (
        <Fragment>
          {AuthService.isAdmin() && (
            <div>
              <h2>{t("Lägg till bilder")}</h2>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>{t("Släpp bilder här")}</p>
                ) : (
                  <p>
                    {t("Drag bilder hit eller klicka för att välja bilder")}
                  </p>
                )}
              </div>
              <div>
                <h3>{t("Godkända filer")}</h3>
                <ul className="list-disc">{acceptedFileItems}</ul>
                <h3>{t("Avvisade filer")}</h3>
                <ul className="list-disc">{fileRejectionItems}</ul>
              </div>
              {getSelectedImages().length > 0 && (
                <Fragment>
                  {getSelectedImages().length === 1 && (
                    <Button
                      text={t("Välj som omslagsbild")}
                      onClick={() => saveCoverPhoto()}
                    ></Button>
                  )}
                  <Button
                    text={t("Ändra valda")}
                    onClick={() => setEditMode(true)}
                  ></Button>

                  <Button
                    text={t("Radera valda")}
                    className="bg-red-700 hover:bg-red-400"
                    onClick={() => deleteImages()}
                  ></Button>
                </Fragment>
              )}
            </div>
          )}
          <h1>
            {album.name} - {dayjs(album.date).format("DD.MM.YYYY")}{" "}
          </h1>
          {getImages()}
        </Fragment>
      )}
      {editMode && (
        <Fragment>
          <div>
            {getSelectedImages().map((image) => {
              return (
                <div className="inline-block" key={image.src}>
                  <img alt={image.comment} src={image.thumbnail} />
                  <label className="block">
                    <span className="text-gray-700">{t("Kommentar")}</span>
                    <textarea
                      className="form-textarea mt-1 block w-full"
                      rows="3"
                      value={image.comment}
                      onInput={(e) => {
                        setAlbum(prev=>{
                          const id = prev.images.findIndex(x=>image._id===x._id)

                          prev.images[id].comment = e.target.value
                          return {...prev}
                        })
                        }}
                      placeholder={t("Skriv din kommentar här")}
                    ></textarea>
                  </label>
                </div>
              );
            })}
          </div>
          <Button text={t("Spara")} onClick={() => saveImages()}></Button>
          <Button
            text={t("Tillbaka")}
            className="bg-red-700 hover:bg-red-400"
            onClick={() => setEditMode(false)}
          ></Button>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Album;
