import axios from "axios";
import authHeader from "./auth-header";

let API_URL = "https://api.amarant.fi/api/";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  API_URL = "http://localhost:8080/api/";
}

const register = (username, email, password) => {
  return axios.post(
    API_URL + "auth/signup",
    {
      username,
      email,
      password,
    },
    {
      headers: authHeader(),
    }
  );
};

const login = (username, password) => {
  return axios
    .post(API_URL + "auth/signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const updatePassword = (userId, token, password) => {
  return axios
    .post(API_URL + `auth/receive_new_password/${userId}/${token}`, {
      password,
    })
    .then((response) => {
      return response.data;
    });
};

const forgotPassword = (email) => {
  return axios
    .post(API_URL + `auth/forgot-password`, {
      email,
    })
    .then((response) => {
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const userHasRole = (role) => {
  if (!getCurrentUser() || !getCurrentUser().roles) {
    return false;
  }
  const roles = getCurrentUser().roles.map((role) => role.name);
  return roles.includes(role);
};

const isAdmin = () => {
  return userHasRole("admin");
};

const isGuest = () => {
  return userHasRole("guest");
};

const tmp = {
  register,
  login,
  logout,
  getCurrentUser,
  isAdmin,
  isGuest,
  updatePassword,
  forgotPassword,
};
export default tmp;
