import React, { useState, useEffect } from "react";
import PageService from "../services/page.service";
import EditorField from "./Slate/EditorField";
import { useTranslation } from "react-i18next";
import { Button } from "./Common/CommonComponents";
import AuthService from "../services/auth.service";
const currentUser = JSON.parse(localStorage.getItem("user"));
const Page = ({ id }) => {
  const [value, setValue] = useState(null);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    PageService.getPage(id, i18n.language).then(
      (response) => {
        if (response.data && response.data.content) {
          setValue(response.data.content);
        } else {
          setValue(null);
        }
      },
      () => {
        setValue(null);
      }
    );
  }, [id, i18n.language]);

  const save = () => {
    PageService.postPage(id, i18n.language, value).then(
      (response) => {
        if (response.data && response.data.content) {
          setValue(response.data.content);
          setSaved(true);
        } else {
          setError(t("Lagring misslyckades"));
          setValue(null);
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(_content);
        setValue(true);
      }
    );
  };
  return (
    <div className="container font-serif">
      <EditorField
        value={value}
        setValue={(e) => {
          setValue(e);
        }}
        currentUser={currentUser}
      />
      {currentUser && AuthService.isAdmin() && (
        <Button text={t("Spara")} onClick={save}></Button>
      )}
      {saved && (
        <div
          className="relative py-3 pl-4 pr-10 leading-normal text-green-700 bg-green-100 rounded-lg"
          role="alert"
        >
          <p>{t("Lagring lyckades")}</p>
          <span
            className="absolute inset-y-0 right-0 flex items-center mr-4"
            onClick={(e) => {
              setSaved(null);
            }}
          >
            <svg
              className="w-4 h-4 fill-current"
              role="button"
              viewBox="0 0 20 20"
            >
              <path
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
          </span>
        </div>
      )}

      {error && (
        <div
          className="relative py-3 pl-4 pr-10 leading-normal text-red-700 bg-red-100 rounded-lg"
          role="alert"
        >
          <p>{error}</p>
          <span
            className="absolute inset-y-0 right-0 flex items-center mr-4"
            onClick={(e) => {
              setError(null);
            }}
          >
            <svg
              className="w-4 h-4 fill-current"
              role="button"
              viewBox="0 0 20 20"
            >
              <path
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
          </span>
        </div>
      )}
    </div>
  );
};

export default Page;
