import React, { useState, useEffect } from "react";
import { Button } from "./Common/CommonComponents";

import UserService from "../services/user.service";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import ObjectsToCsv from "objects-to-csv";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [filterUnpaid, setFilterUnpaid] = useState(false);
  const [filterAttending, setFilterAttending] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    refreshUsers();
  }, []);

  const refreshUsers = () => {
    UserService.getAllUsers().then(
      (response) => {
        setUsers(response.data);
      },
      () => {
        setUsers([]);
      }
    );
  };

  async function printCsv(data) {
    const string = await new ObjectsToCsv(data).toString(true, true);
    const csvContent = "data:text/csv;charset=utf-8," + string;
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  const downLoadCSV = () => {
    printCsv(
      users.map((user) => {
        const tmpUser = { ...user };
        delete tmpUser.password;
        tmpUser.gender = tmpUser.man ? t("Man") : t("Kvinna");
        delete tmpUser.man;
        tmpUser.roles = tmpUser.roles
          ? tmpUser.roles.map((roles) => roles.name).join(",")
          : null;
        tmpUser.grade = tmpUser.grade ? tmpUser.grade.name : null;
        delete tmpUser._id;
        delete tmpUser._v;
        delete tmpUser.__v;
        tmpUser.paid = tmpUser.paid
          ? tmpUser.paid.map((paid) => new Date(paid).getFullYear()).join(",")
          : null;
        tmpUser.ballsAttended = tmpUser.paid
          ? tmpUser.ballsAttended
              .map((paid) => new Date(paid).getFullYear())
              .join(",")
          : null;
        tmpUser.birthDate = tmpUser.birthDate
          ? new Date(tmpUser.birthDate).toLocaleDateString()
          : null;
        tmpUser.createdAt = tmpUser.createdAt
          ? new Date(tmpUser.createdAt).toLocaleDateString()
          : null;
        tmpUser.gradeSince = tmpUser.gradeSince
          ? new Date(tmpUser.gradeSince).toLocaleDateString()
          : null;
        tmpUser.memberSince = tmpUser.memberSince
          ? new Date(tmpUser.memberSince).toLocaleDateString()
          : null;
        tmpUser.updatedAt = tmpUser.updatedAt
          ? new Date(tmpUser.updatedAt).toLocaleDateString()
          : null;
        return tmpUser;
      })
    );
  };

  const pay = (user) => {
    user.paid.push(new Date());
    UserService.updateUsers(user).then(() => {
      refreshUsers();
    });
  };

  // const showUser = () => {
  //   if (currentUser) {
  //     return (
  //       <Fragment>
  //         <div>{currentUser.username}</div>
  //         <div>{currentUser.address}</div>
  //         <div>{currentUser.postNumber}</div>
  //         <div>{currentUser.postalOffice}</div>
  //         <div>{currentUser.country}</div>
  //         <div>{currentUser.avec}</div>
  //         <div>{currentUser.comment}</div>
  //         <div>{currentUser.email}</div>
  //         <div>{currentUser.roles.map((role) => role.name + " ")}</div>
  //         <div>
  //           <Link to={"/profile/" + currentUser._id}>
  //             <button className="text-indigo-600 hover:text-indigo-900">
  //               {t("Ändra")}
  //             </button>
  //           </Link>
  //         </div>{" "}
  //       </Fragment>
  //     );
  //   } else {
  //     return <div></div>;
  //   }
  // };

  function filterByValue(array, string) {
    return array.filter((o) => {
      return Object.keys(o).some((k) => {
        if (Array.isArray(o[k])) {
          return filterByValue(o[k], string).length > 0;
        } else if (typeof o[k] === "object" && o[k] !== null) {
          return Object.keys(o[k]).some((l) => {
            if (
              o[k][l] === null ||
              typeof o[k][l] === "boolean" ||
              !o[k][l].toLowerCase
            ) {
              return false;
            }
            return o[k][l].toLowerCase().includes(string.toLowerCase());
          });
        } else if (
          o[k] === null ||
          typeof o[k] === "boolean" ||
          !o[k].toLowerCase
        ) {
          return false;
        }
        return o[k].toLowerCase().includes(string.toLowerCase());
      });
    });
  }
  let filteredUsers = users;
  if (searchString) {
    filteredUsers = filterByValue(users, searchString);
  }
  if (filterUnpaid) {
    filteredUsers = filteredUsers.filter((user) => {
      const current = new Date().getFullYear();
      const paidYears = user.paid.map((date) => new Date(date).getFullYear());
      return !paidYears.includes(current);
    });
  }
  if (filterAttending) {
    filteredUsers = filteredUsers.filter((user) => {
      const current = new Date().getFullYear();
      const ballsAttended = user.ballsAttended.map((date) =>
        new Date(date).getFullYear()
      );
      return ballsAttended.includes(current);
    });
  }
  let heading = t("Alla användare");
  if (filterAttending) {
    heading = t("Baldeltagare");
  } else if (filterUnpaid) {
    heading = t("Användare med obetald medlemsavgift");
  }
  return (
    <Fragment>
      <div className="shadow flex">
        <input
          className="w-full rounded p-2"
          type="text"
          value={searchString}
          placeholder={t("Sök...")}
          onInput={(e) => {
            setSearchString(e.target.value);
          }}
        />
        <button className="bg-white w-auto flex justify-end items-center text-cyan-500 p-2 hover:text-cyan-400">
          <i className="material-icons">search</i>
        </button>
      </div>
      <Button
        text={t("Exportera till CSV (Excel)")}
        onClick={() => downLoadCSV()}
      ></Button>
      {!filterUnpaid && !filterAttending && (
        <Button
          text={t("Visa obetalda")}
          onClick={() => setFilterUnpaid(true)}
        ></Button>
      )}
      {filterUnpaid && (
        <Button
          text={t("Visa alla")}
          onClick={() => setFilterUnpaid(false)}
        ></Button>
      )}
      {!filterAttending && !filterUnpaid && (
        <Button
          text={t("Visa baldeltagare")}
          onClick={() => setFilterAttending(true)}
        ></Button>
      )}
      {filterAttending && (
        <Button
          text={t("Visa alla")}
          onClick={() => setFilterAttending(false)}
        ></Button>
      )}
      <h1 className="text-center">{heading}</h1>
      <div className="container flex flex-wrap ">
        {filteredUsers.map((user) => {
          return (
            <Link
              to={"/profile/" + user._id}
              key={user._id}
              className="card border w-max hover:shadow-none relative flex-col mx-auto shadow-lg m-2"
            >
              {user.grade && (
                <div className="name font-sans text-lg font-bold">
                  {user.man ? user.grade.name : user.grade.feminineName}
                </div>
              )}
              <div className="name font-sans text-lg font-bold">
                {user.name} {user.surname}
              </div>
              {user.email && (
                <div>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-4 h-4 inline-block"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>{" "}
                  {user.email}
                </div>
              )}
              {(user.address ||
                user.postNumber ||
                user.postalOffice ||
                user.country) && (
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-4 h-4 inline-block"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>{" "}
                  {user.address} {user.postNumber} {user.postalOffice}{" "}
                  {user.country}
                </div>
              )}
              {user.avec && (
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-4 h-4 inline-block"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>{" "}
                  {user.avec.name} {user.avec.surname}
                </div>
              )}
              {user.paid.length > 0 ||
                (user.ballsAttended.length > 0 && (
                  <div>
                    <div className="inline-block">
                      {user.paid.map((year) => {
                        {
                          if (
                            new Date(year).getFullYear() ==
                            new Date().getFullYear()
                          ) {
                            return (
                              <div>
                                <svg
                                  className="w-4 h-4 inline-block"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M3 6V18H13.32C13.1 17.33 13 16.66 13 16H7C7 14.9 6.11 14 5 14V10C6.11 10 7 9.11 7 8H17C17 9.11 17.9 10 19 10V10.06C19.67 10.06 20.34 10.18 21 10.4V6H3M12 9C10.3 9.03 9 10.3 9 12C9 13.7 10.3 14.94 12 15C12.38 15 12.77 14.92 13.14 14.77C13.41 13.67 13.86 12.63 14.97 11.61C14.85 10.28 13.59 8.97 12 9M21.63 12.27L17.76 16.17L16.41 14.8L15 16.22L17.75 19L23.03 13.68L21.63 12.27Z"
                                  />
                                </svg>{" "}
                                {t("Betald")}
                              </div>
                            );
                          }
                        }
                      })}
                    </div>
                    <div className="inline-block">
                      {user.ballsAttended.map((year) => {
                        {
                          if (
                            new Date(year).getFullYear() ==
                            new Date().getFullYear()
                          ) {
                            return (
                              <div>
                                <svg
                                  className="w-4 h-4 inline-block"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M14 3.5C14 4.33 13.33 5 12.5 5S11 4.33 11 3.5 11.67 2 12.5 2 14 2.67 14 3.5M8.5 5C7.67 5 7 5.67 7 6.5S7.67 8 8.5 8 10 7.33 10 6.5 9.33 5 8.5 5M14 12L13.22 9.75H16.18L18.34 8.67C18.71 8.5 18.86 8.04 18.67 7.67C18.5 7.3 18.04 7.14 17.67 7.33L16.85 7.74L16.36 6.9C16.07 6.25 15.36 5.88 14.66 6.04L12.19 6.57C11.5 6.72 11 7.35 11 8.07V8.77L8.57 10.39H8.58C8.5 10.46 8.39 10.55 8.33 10.67L7.44 12.44L5.66 13.33C5.29 13.5 5.14 13.97 5.33 14.34C5.46 14.6 5.73 14.75 6 14.75C6.11 14.75 6.23 14.73 6.34 14.67L8.56 13.56L9.6 11.5L11 13C10 16 3 20 3 20S7 22 12 22 21 20 21 20 16 16 14 12M16.85 11.09L16.53 11.25H15.33L15.39 11.41C15.91 12.44 16.67 13.5 17.5 14.44L16.97 11.03L16.85 11.09Z"
                                  />
                                </svg>{" "}
                                {t("Deltar på bal")}
                              </div>
                            );
                          }
                        }
                      })}
                    </div>
                  </div>
                ))}
              {user.comment && <div className="italic">{user.comment}</div>}
              <div>
                {user.roles.map((role) => {
                  return (
                    <div
                      key={role._id}
                      className="mr-1 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full bg-white text-gray-700 border"
                    >
                      {role.name + " "}
                    </div>
                  );
                })}
              </div>
              {filterUnpaid && (
                <Button
                  text={t("Betalt")}
                  onClick={(e) => {
                    e.preventDefault();
                    pay(user);
                  }}
                ></Button>
              )}
            </Link>
          );
        })}
      </div>
    </Fragment>
  );
};

export default UserList;
