import axios from "axios";
import authHeader from "./auth-header";


let API_URL = "https://api.amarant.fi/api/";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  API_URL = "http://localhost:8080/api/";

} 


const getPage = (id, lang) => {
  return axios.get(API_URL + "page", {
    headers: authHeader(),
    params: { id, lang },
  });
};

const postPage = (id, lang, content) => {
  return axios.post(
    API_URL + `page/${id}/${lang}`,
    {
      content: content,
    },
    { headers: authHeader() }
  );
};

const tmp = {
    getPage,
    postPage,
};
export default tmp;
