import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const getIdentifier = (keys, object) => {
  if (!object) {
    return "";
  }
  return keys.reduce((acc, key) => {
    if (key in object) {
      acc = `${acc} ${object[key]}`;
    }

    return acc;
  }, "");
};
export const Button = ({ text, onClick, className }) => {
  className = className || "bg-blue-700 hover:bg-blue-400";

  return (
    <button
      className={
        " mr-1 text-white font-bold py-2 px-4 rounded mt-2 mb-2 " + className
      }
      onClick={onClick}
    >
      {text}
    </button>
  );
};
export const Input = ({
  id,
  name,
  label,
  inset,
  onChange,
  value,
  placeholder,
  type,

  autocomplete,
}) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{inset}</span>
        </div>
        <input
          type={type ? type : "text"}
          name={name}
          id={id}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          autoComplete={autocomplete ? "on" : "off"}
        />
      </div>
    </div>
  );
};

export const Multiselect = ({
  available,
  selected,
  setSelected: setParentSelected,
  id,
  label,
}) => {
  // state showing if dropdown is open or closed
  const [dropdown, setDropdown] = useState(false);
  // managing dropdown items (list of dropdown items)
  const [items, setItems] = useState(available);
  // contains selected items
  const [selectedItems, setSelected] = useState(selected);

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };
  // adds new item to multiselect
  const addTag = (item) => {
    setSelected(selectedItems.concat(item));
    setParentSelected(selectedItems.concat(item));
    setDropdown(false);
  };
  // removes item from multiselect
  const removeTag = (item) => {
    const filtered = selectedItems.filter((e) => e !== item);
    setSelected(filtered);
    setParentSelected(filtered);
  };

  return (
    <Fragment>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="autcomplete-wrapper">
        <div className="autcomplete">
          <div className="w-full flex flex-col items-center mx-auto">
            <div className="w-full">
              <div className="flex flex-col items-center relative">
                <div className="w-full ">
                  <div className="my-2 p-1 flex border border-gray-200 bg-white rounded ">
                    <div className="flex flex-auto flex-wrap">
                      {selectedItems.map((tag, index) => {
                        return (
                          <div
                            key={index}
                            className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 "
                          >
                            <div className="text-xs font-normal leading-none max-w-full flex-initial">
                              {tag}
                            </div>
                            <div className="flex flex-auto flex-row-reverse">
                              <div onClick={() => removeTag(tag)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="100%"
                                  height="100%"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2"
                                >
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex-1">
                        <input
                          id={id}
                          placeholder=""
                          className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                        />
                      </div>
                    </div>
                    <div
                      className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
                      onClick={toggleDropdown}
                    >
                      <button className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-chevron-up w-4 h-4"
                        >
                          <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {dropdown ? (
                <Dropdown list={items} addItem={addTag}></Dropdown>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export const Dropdown = ({ list, addItem }) => {
  return (
    <div
      id="dropdown"
      className="absolute  shadow top-100 bg-white z-40 w-auto lef-0 rounded max-h-select overflow-y-auto "
    >
      <div className="flex flex-col w-full">
        {list.map((item, key) => {
          return (
            <div
              key={key}
              className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
              onClick={() => addItem(item)}
            >
              <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                <div className="w-full items-center flex">
                  <div className="mx-2 leading-6  ">{item}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// https://tailwindui.com/components/application-ui/forms/select-menus
export const Select = ({ label, available, initial, onSelect }) => {
  const [dropdown, setDropdown] = useState(false);
  const [current, setCurrent] = useState(initial || {});

  const handleSelect = (e) => {
    setCurrent(e);
    onSelect(e);
    setDropdown(false);
    console.log("handleSelect")
  };
  return (
    <div
      onFocus={(e) => {
        setDropdown(true);
        console.log("onFocus")
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setDropdown(false);console.log("onBlur")
        }
      }}
    >
      <label
        id="listbox-label"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 relative">
        <div 
          tabindex="0"
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <span className="flex items-center">
            {/* <img
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
              className="flex-shrink-0 h-6 w-6 rounded-full"
            /> */}
            <span className="ml-3 block truncate">{current.name}</span>
          </span>
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            {/* <!-- Heroicon name: solid/selector --> */}
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              f
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>

        {/* <!--  const { t } = useTranslation();

      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    --> */}

        {dropdown && (
          <SingleDropDown
            available={available}
            handleSelect={handleSelect}
            current={current}
          ></SingleDropDown>
        )}
      </div>
    </div>
  );
};

export const SingleDropDown = ({
  available,
  handleSelect,
  current,
  nameKeys = ["name"],
}) => {
  const [hovered, setHovered] = useState(null);
  const currentName = getIdentifier(nameKeys, current);
  return (
    <div className=" mt-1 w-full rounded-md bg-white shadow-lg z-10">
      <ul
        tabIndex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-item-3"
        className="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      >
        {available.map((item) => {
          const name = getIdentifier(nameKeys, item);
          /* <!--
    Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

    Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
  --> */

          return (
            <li
              id="listbox-item-0"
              className={
                " cursor-default select-none relative py-2 pl-3 pr-9 " +
                (hovered === name
                  ? "text-white bg-indigo-600"
                  : "text-gray-900")
              }
              onClick={(e) => handleSelect(item)}
              key={name}
              onMouseOver={() => setHovered(name)}
              onMouseOut={() => setHovered(null)}
            >
              <div className="flex items-center">
                {/* <img
                  src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                  alt=""
                  className="flex-shrink-0 h-6 w-6 rounded-full"
                /> */}
                {/* <!-- Selected: "font-semibold", Not Selected: "font-normal" --> */}
                <span
                  className={
                    "ml-3 block  truncate " +
                    (name === currentName ? "font-semibold" : "font-normal")
                  }
                >
                  {name}
                </span>
              </div>
              {/* 
  <!--
    Checkmark, only display for selected option.

    Highlighted: "text-white", Not Highlighted: "text-indigo-600"
  --> */}
              {name === currentName && (
                <span
                  className={
                    "absolute inset-y-0 right-0 flex items-center pr-4 " +
                    (hovered === name ? "text-white " : "text-indigo-600")
                  }
                >
                  {/* <!-- Heroicon name: solid/check --> */}
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              )}
            </li>
          );
        })}

        {/* <!-- More items... --> */}
      </ul>
    </div>
  );
};

export const Toggle = ({ value, setValue, label }) => {
  return (
    <Fragment>
      {!value && (
        <label
          onClick={(e) => setValue(true)}
          for="unchecked"
          className="mt-3 inline-flex items-center cursor-pointer"
        >
          <span className="relative">
            <span className="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
            <span className="absolute block w-4 h-4 mt-1 ml-1 bg-white rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out">
              <input
                id="unchecked"
                type="checkbox"
                className="absolute opacity-0 w-0 h-0"
              />
            </span>
          </span>
          <span className="ml-3 text-sm">{label}</span>
        </label>
      )}

      {value && (
        <label
          onClick={(e) => setValue(false)}
          for="checked"
          className="mt-3 inline-flex items-center cursor-pointer"
        >
          <span className="relative">
            <span className="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
            <span className="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-purple-600 transform translate-x-full">
              <input
                id="checked"
                type="checkbox"
                className="absolute opacity-0 w-0 h-0"
              />
            </span>
          </span>
          <span className="ml-3 text-sm">{label}</span>
        </label>
      )}
    </Fragment>
  );
};

export const AutoCompleteSelect = ({
  label,
  available,
  initial,
  onSelect,
  setSearchString,
  searchString,
  nameKeys,
  placeholder,
}) => {
  function filterByValue(array, string) {
    return array.filter((o) => {
      return Object.keys(o).some((k) => {
        if (Array.isArray(o[k])) {
          return filterByValue(o[k], string).length > 0;
        } else if (typeof o[k] === "object" && o[k] !== null) {
          return Object.keys(o[k]).some((l) => {
            if (!o[k][l].toLowerCase) {
              return false;
            }
            return o[k][l].toLowerCase().includes(string.toLowerCase());
          });
        } else if (!o[k].toLowerCase) {
          return false;
        }
        return o[k].toLowerCase().includes(string.toLowerCase());
      });
    });
  }
  const { t } = useTranslation();
  const [availablePlusNull, setAvailablePlusNull] = useState(available);
  const [dropDown, setDropDown] = useState(false);
  const handleFilterChange = (e) => {
    setSearchString(e.target.value);
  };

  const handleSelect = (item) => {
    setSearchString(getIdentifier(nameKeys, item));
    if (!item.null) {
      onSelect(item);
    } else {
      onSelect(null);
    }
    setDropDown(false);
  };
  useEffect(() => {
    setAvailablePlusNull([{ name: t("Ingen Avec"), null: true }, ...available]);
  }, [available]);

  return (
    <div
      onFocus={(e) => {
        setDropDown(true);
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setDropDown(false);
        }
      }}
    >
      <Input
        label={label}
        value={searchString}
        name={label}
        id={label}
        onChange={handleFilterChange}
        placeholder={placeholder}
      ></Input>{" "}
      {dropDown && (
        <SingleDropDown
          available={availablePlusNull}
          handleSelect={handleSelect}
          current={initial}
          nameKeys={nameKeys}
        ></SingleDropDown>
      )}
    </div>
  );
};
