import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import AuthService from "../services/auth.service";

const Nav = () => {
  const [showUsers, setShowUsers] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const logOut = () => {
    AuthService.logout();
    toggleProfile();
  };
  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setShowUsers(AuthService.isAdmin());
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen((prev) => {
      return !prev;
    });
  };
  const toggleProfile = () => {
    setProfileOpen((prev) => {
      return !prev;
    });
  };

  const changeLanguage = (lng) => {
    toggleProfile();
    i18n.changeLanguage(lng);
  };
  return (
    <Fragment>
      <nav id="nav" className="shadow border-b-2 border-red-700">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* <!-- Mobile menu button--> */}
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-red-400  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false"
                onClick={toggleMenu}
              >
                <span className="sr-only">{t("Öppna huvudmenyn")}</span>
                {/* <!-- Icon when menu is closed. -->
<!--
  Heroicon name: menu

  Menu open: "hidden", Menu closed: "block"
--> */}
                <svg
                  className={`${menuOpen ? "hidden" : "block"} h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                {/* <!-- Icon when menu is open. -->
<!--
  Heroicon name: x

  Menu open: "block", Menu closed: "hidden"
--> */}
                <svg
                  className={`${menuOpen ? "block" : "hidden"} h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="block h-8 w-auto mx-2"
                  src="pieni_a.png"
                  alt="Logo"
                />
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {/* <!-- Current: " text-red-500", Default: "text-red-700  hover:text-red-400" --> */}
                  <NavLink
                    to={"/home"}
                    activeClassName=" text-red-700 px-3 py-2 rounded-md text-sm font-medium"
                    className=" hover:text-red-400 px-3 py-2 rounded-md text-sm font-medium"
                    onClick={toggleMenu}
                  >
                    {t("Framsidan")}
                  </NavLink>
                  <NavLink
                    to={"/history"}
                    activeClassName=" text-red-700 px-3 py-2 rounded-md text-sm font-medium"
                    className=" hover:text-red-400 px-3 py-2 rounded-md text-sm font-medium"
                    onClick={toggleMenu}
                  >
                    {t("Historia")}
                  </NavLink>
                  {currentUser && (
                    <NavLink
                      to={"/board"}
                      activeClassName=" text-red-700 px-3 py-2 rounded-md text-sm font-medium"
                      className=" hover:text-red-400 px-3 py-2 rounded-md text-sm font-medium"
                      onClick={toggleMenu}
                    >
                      {t("Direktionen")}
                    </NavLink>
                  )}
                  {currentUser && (
                    <NavLink
                      to={"/grades"}
                      activeClassName=" text-red-700 px-3 py-2 rounded-md text-sm font-medium"
                      className=" hover:text-red-400 px-3 py-2 rounded-md text-sm font-medium"
                      onClick={toggleMenu}
                    >
                      {t("Grader")}
                    </NavLink>
                  )}
                  {currentUser && (
                    <NavLink
                      to={"/gallery"}
                      activeClassName=" text-red-700 px-3 py-2 rounded-md text-sm font-medium"
                      className=" hover:text-red-400 px-3 py-2 rounded-md text-sm font-medium"
                      onClick={toggleMenu}
                    >
                      {t("Bildgalleri")}
                    </NavLink>
                  )}
                  <NavLink
                    to={"/next-bal"}
                    activeClassName=" text-red-700 px-3 py-2 rounded-md text-sm font-medium"
                    className=" hover:text-red-400 px-3 py-2 rounded-md text-sm font-medium"
                    onClick={toggleMenu}
                  >
                    {t("Följande bal")}
                  </NavLink>

                  <NavLink
                    to={"/new-members"}
                    activeClassName=" text-red-700 px-3 py-2 rounded-md text-sm font-medium"
                    className=" hover:text-red-400 px-3 py-2 rounded-md text-sm font-medium"
                    onClick={toggleMenu}
                  >
                    {t("Nya medlemmar")}
                  </NavLink>

                  {/* {showModeratorBoard && (
                  <NavLink
                    to={"/mod"}
                    activeClassName=" text-red-700 px-3 py-2 rounded-md text-sm font-medium"
                    className=" hover:text-red-400 px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Moderator Board
                  </NavLink>
                )} */}

                  {showUsers && (
                    <NavLink
                      to={"/users"}
                      activeClassName=" text-red-700 px-3 py-2 rounded-md text-sm font-medium"
                      className=" hover:text-red-400 px-3 py-2 rounded-md text-sm font-medium"
                      onClick={toggleMenu}
                    >
                      {t("Användare")}
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="ml-3 relative">
                <div>
                  <button
                    className="bg-white-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-800 focus:ring-red-800"
                    id="user-menu"
                    aria-haspopup="true"
                    onClick={toggleProfile}
                  >
                    <span className="sr-only">{t("Öppna profilmenyn")}</span>

                    <img
                      className="h-8 w-8 rounded-full"
                      src={"account.png"}
                      alt=""
                    />
                  </button>
                </div>
                <div
                  className={`${
                    profileOpen ? "block" : "hidden"
                  } origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-10 `}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div className="navbar-nav ml-auto">
                    {currentUser ? (
                      <Fragment>
                        <Link
                          to={"/profile"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={toggleProfile}
                        >
                          {currentUser.username}
                        </Link>
                        <a
                          href="/login"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={logOut}
                        >
                          {t("Logga ut")}
                        </a>
                        {AuthService.isAdmin() && (
                          <Link
                            to={"/register"}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={toggleProfile}
                          >
                            {t("Skapa ny användare")}
                          </Link>
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Link
                          to={"/login"}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={toggleProfile}
                        >
                          {t("Logga in")}
                        </Link>
                      </Fragment>
                    )}
                    <button
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => changeLanguage("sv")}
                    >
                      {t("På svenska")}
                    </button>

                    <button
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => changeLanguage("fi")}
                    >
                      {t("På Finska")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--
Mobile menu, toggle classes based on menu state.

Menu open: "block", Menu closed: "hidden"
--> */}
        <div className={`${menuOpen ? "block " : "hidden"}  sm:hidden`}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {/* <!-- Current: " text-red-500", Default: "text-red-700  hover:text-red-400" --> */}
            <NavLink
              to={"/home"}
              activeClassName=" text-red-700 block px-3 py-2 rounded-md text-base font-medium"
              className=" hover:text-red-400 block px-3 py-2 rounded-md text-base font-medium"
              onClick={toggleMenu}
            >
              {t("Framsidan")}
            </NavLink>
            {currentUser && (
              <NavLink
                to={"/grades"}
                activeClassName=" text-red-700 block px-3 py-2 rounded-md text-base font-medium"
                className=" hover:text-red-400 block px-3 py-2 rounded-md text-base font-medium"
                onClick={toggleMenu}
              >
                {t("Grader")}
              </NavLink>
            )}

            <NavLink
              to={"/history"}
              activeClassName=" text-red-700 block px-3 py-2 rounded-md text-base font-medium"
              className=" hover:text-red-400 block px-3 py-2 rounded-md text-base font-medium"
              onClick={toggleMenu}
            >
              {t("Historia")}
            </NavLink>
            {currentUser && (
              <NavLink
                to={"/board"}
                activeClassName=" text-red-700 block px-3 py-2 rounded-md text-base font-medium"
                className=" hover:text-red-400 block px-3 py-2 rounded-md text-base font-medium"
                onClick={toggleMenu}
              >
                {t("Direktionen")}
              </NavLink>
            )}
            {currentUser && (
              <NavLink
                to={"/grades"}
                activeClassName=" text-red-700 block px-3 py-2 rounded-md text-base font-medium"
                className=" hover:text-red-400 block px-3 py-2 rounded-md text-base font-medium"
                onClick={toggleMenu}
              >
                {t("Grader")}
              </NavLink>
            )}
            {currentUser && (
              <NavLink
                to={"/gallery"}
                activeClassName=" text-red-700 block px-3 py-2 rounded-md text-base font-medium"
                className=" hover:text-red-400 block px-3 py-2 rounded-md text-base font-medium"
                onClick={toggleMenu}
              >
                {t("Bildgalleri")}
              </NavLink>
            )}

            <NavLink
              to={"/next-bal"}
              activeClassName=" text-red-700 block px-3 py-2 rounded-md text-base font-medium"
              className=" hover:text-red-400 block px-3 py-2 rounded-md text-base font-medium"
              onClick={toggleMenu}
            >
              {t("Följande bal")}
            </NavLink>

            <NavLink
              to={"/new-members"}
              activeClassName=" text-red-700 block px-3 py-2 rounded-md text-base font-medium"
              className=" hover:text-red-400 block px-3 py-2 rounded-md text-base font-medium"
              onClick={toggleMenu}
            >
              {t("Nya medlemmar")}
            </NavLink>

            {showUsers && (
              <NavLink
                to={"/users"}
                activeClassName=" text-red-700 block px-3 py-2 rounded-md text-base font-medium"
                className=" hover:text-red-400 block px-3 py-2 rounded-md text-base font-medium"
                onClick={toggleMenu}
              >
                {t("Användare")}
              </NavLink>
            )}
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Nav;
