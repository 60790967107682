import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import {
  Button,
  Toggle,
  Input,
  Multiselect,
  Select,
  AutoCompleteSelect,
} from "./Common/CommonComponents";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import UserService from "../services/user.service";
import { Fragment } from "react";
import axios from "axios";

const availableYears = function () {
  let startYear = 2020;
  var currentYear = new Date().getFullYear(),
    years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

const Profile = () => {
  const history = useHistory();
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  let currentUser;
  if (!id) {
    currentUser = AuthService.getCurrentUser();
  }
  const { t } = useTranslation();
  const [user, setUser] = useState(currentUser);
  const [status, setStatus] = useState(null);
  const [availableAvecs, setAvailableAvecs] = useState([]);
  const [availableRoles, setAvailableRoles] = useState();
  const [availableGrades, setAvailableGrades] = useState();
  const [avecSearch, setAvecSearch] = useState("");

  useEffect(() => {
    if (avecSearch.length > 1) {
      UserService.searchUsers(avecSearch).then((resp) => {
        setAvailableAvecs(resp.data);
      });
    } else {
      setAvailableAvecs([]);
    }
  }, [avecSearch]);
  useEffect(() => {
    if (id) {
      UserService.getUser(id).then((resp) => {
        setUser(resp.data);
        setAvecSearch(
          resp.data.avec ? resp.data.avec.name + " " + resp.data.avec.surname : ""
        );
      });
    }
    if (AuthService.isAdmin()) {
      UserService.getAllRoles().then((resp) => {
        setAvailableRoles(resp.data);
      });
      UserService.getAllGrades().then((resp) => {
        setAvailableGrades(resp.data.sort((a, b) => a.rank > b.rank));
      });
    }
  }, [id]);
  const save = () => {
    UserService.updateUsers(user).then(
      (resp) => {
        if (!id) {
          const newUser = resp.data;
          const prevUser = JSON.parse(localStorage.getItem("user"));

          prevUser.name = newUser.name;
          prevUser.surname = newUser.surname;
          prevUser.address = newUser.address;
          prevUser.email = newUser.email;
          prevUser.postNumber = newUser.postNumber;
          prevUser.postalOffice = newUser.postalOffice;
          prevUser.country = newUser.country;
          prevUser.avec = newUser.avec;
          prevUser.comment = newUser.comment;
          prevUser.grade = newUser.grade;
          prevUser.man = newUser.man;
          prevUser.paid = newUser.paid;
          prevUser.ballsAttended = newUser.ballsAttended;
          prevUser.memberSince = newUser.memberSince;
          prevUser.birthDate = newUser.birthDate;
          localStorage.setItem("user", JSON.stringify(prevUser));
        }
        setEditMode(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setStatus(resMessage);
      }
    );
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const setSelected = (e) => {
    const newRoles = [];
    availableRoles.forEach((availableRole) => {
      if (e.includes(availableRole.name)) {
        newRoles.push(availableRole);
      }
    });
    setUser({ ...user, roles: newRoles });
  };

  const setPaid = (e) => {
    const newPayment = [];
    availableYears().forEach((availableYear) => {
      if (e.includes(availableYear)) {
        newPayment.push(new Date(availableYear, 1, 1));
      }
    });
    setUser({ ...user, paid: newPayment });
  };

  const setBallsAttended = (e) => {
    const newPayment = [];
    availableYears().forEach((availableYear) => {
      if (e.includes(availableYear)) {
        newPayment.push(new Date(availableYear, 1, 1));
      }
    });
    setUser({ ...user, ballsAttended: newPayment });
  };

  const setSelectedGrade = (grade) => {
    setUser({ ...user, grade: grade });
  };

  const setGender = (gender) => {
    setUser({ ...user, man: gender.isMan });
  };

  const setAvec = (avec) => {
    setUser({ ...user, avec: avec });
  };

  if (!user) {
    return <div>{t("Laddar")}</div>;
  }

  const deleteUser = () => {
    UserService.deleteUser(user._id);
    history.push("/users");
  };
  if (!editMode) {
    return (
      <div>
        <header className="jumbotron">
          <h3>
            {user.man ? user.grade.name : user.grade.feminineName} {user.name}{" "}
            <strong>{user.surname}</strong>
          </h3>
        </header>
        <p>
          <strong>{t("Användarnamn")}:</strong> {user.username}
        </p>
        <p>
          <strong>{t("Epost")}:</strong> {user.email}
        </p>
        <p>
          <strong>{t("Adress")}:</strong> {user.address} {user.postNumber}{" "}
          {user.postalOffice} {user.country}
        </p>
        <p>
          <strong>{t("Födelsedatum")}:</strong>{" "}
          {user.birthDate ? new Date(user.birthDate).toLocaleDateString() : ""}
        </p>
        <p>
          <strong>{t("Kön")}:</strong> {user.man ? t("Man") : t("Kvinna")}
        </p>
        {user.avec && (
          <p>
            <strong>{t("Avec")}:</strong> {user.avec.name}{" "}
            <strong>{user.avec.surname}</strong>
          </p>
        )}
        <p>
          <strong>{t("Kommentar")}:</strong> {user.comment}
        </p>
        <p>
          <strong>{t("Medlem sedan")}:</strong>{" "}
          {user.memberSince
            ? new Date(user.memberSince).toLocaleDateString()
            : ""}
        </p>
        <p>
          <strong>{t("Betalt")}:</strong>{" "}
          {user.paid.map((year) => {
            return new Date(year).getFullYear() + " ";
          })}
        </p>
        <p>
          <strong>{t("Baler")}:</strong>{" "}
          {user.ballsAttended.map((year) => {
            return new Date(year).getFullYear() + " ";
          })}
        </p>

        <p>
          <strong>{t("Roller")}:</strong>{" "}
          {user.roles.map((role) => {
            return role.name + " ";
          })}
        </p>
        <Button text={t("Ändra")} onClick={() => setEditMode(true)}></Button>
        <Button
          className="bg-red-700 hover:bg-red-400"
          text={t("Tag Bort")}
          onClick={() =>
            window.confirm(
              t("Är du säker på att du vill ta bort användaren?")
            ) && deleteUser()
          }
        ></Button>
      </div>
    );
  } else {
    return (
      <div>
        <strong>{t("Användarnamn")}:</strong> {user.username}
        <Input
          label={t("Förnamn")}
          value={user.name}
          name="name"
          id="name"
          onChange={handleChange}
        ></Input>
        <Input
          label={t("Efternamn")}
          value={user.surname}
          name="surname"
          id="surname"
          onChange={handleChange}
        ></Input>
        <Input
          label={t("Adress")}
          value={user.address}
          name="address"
          id="address"
          onChange={handleChange}
        ></Input>
        <Input
          label={t("Postnummer")}
          value={user.postNumber}
          name="postNumber"
          id="postNumber"
          onChange={handleChange}
        ></Input>
        <Input
          label={t("Postanstalt")}
          value={user.postalOffice}
          name="postalOffice"
          id="postalOffice"
          onChange={handleChange}
        ></Input>
        <Input
          label={t("Land")}
          value={user.country}
          name="country"
          id="country"
          onChange={handleChange}
        ></Input>
        <Input
          placeholder={t("Födelsedatum")}
          name="birthDate"
          value={user.birthDate}
          label={t("Födelsedatum")}
          id="birthDate"
          type="date"
          onChange={handleChange}
        />
        <AutoCompleteSelect
          label={t("Avec")}
          available={availableAvecs}
          initial={user.avec}
          searchString={avecSearch}
          setSearchString={setAvecSearch}
          onSelect={setAvec}
          nameKeys={["name", "surname"]}
          placeholder={t("Skriv minimum två bokstäver för att söka bland medlemmar")}
        ></AutoCompleteSelect>
        <Input
          label={t("Kommentar")}
          value={user.comment}
          name="comment"
          id="comment"
          onChange={handleChange}
        ></Input>
        <Input
          label={t("Epost")}
          value={user.email}
          name="email"
          id="email"
          onChange={handleChange}
        ></Input>
        <Select
          label={t("Kön")}
          available={[
            { name: t("Man"), isMan: true },
            { name: t("Kvinna"), isMan: false },
          ]}
          initial={{ name: user.man ? t("Man") : t("Kvinna"), isMan: user.man }}
          onSelect={setGender}
        ></Select>
        <Input
          placeholder={t("Medlem sedan")}
          name="memberSince"
          value={user.memberSince}
          label={t("Medlem sedan")}
          id="memberSince"
          type="date"
          onChange={handleChange}
        />
        {AuthService.isAdmin() && (
          <Fragment>
            <Multiselect
              label={t("Betalt")}
              id="paid"
              available={availableYears}
              selected={user.paid.map((date) => new Date(date).getFullYear())}
              setSelected={setPaid}
            ></Multiselect>
            <Multiselect
              label={t("Baler")}
              id="ballsAttended"
              available={availableYears}
              selected={user.ballsAttended.map((date) =>
                new Date(date).getFullYear()
              )}
              setSelected={setBallsAttended}
            ></Multiselect>

            <Select
              label={t("Grad")}
              available={availableGrades}
              initial={user.grade}
              onSelect={setSelectedGrade}
            ></Select>
            <Multiselect
              label={t("Roller")}
              available={availableRoles.map((role) => role.name)}
              selected={user.roles.map((role) => role.name)}
              setSelected={setSelected}
            ></Multiselect>
          </Fragment>
        )}
        {/* <Input label={t('Lösenord')} value={currentUser.password} name="password" id="password" type='password'></Input> */}
        <Button text={t("Spara")} onClick={() => save()}></Button>
        {status && (
          <div className="inline-block text-red px-2 py-2 rounded-md text-sm font-medium">
            {status}
          </div>
        )}
      </div>
    );
  }
};

export default Profile;
