import React, { useState, useEffect, Fragment } from "react";
import GalleryService from "../../services/gallery.service";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { Button } from "../Common/CommonComponents";
import AuthService from "../../services/auth.service";

const Gallery = () => {
  const [albums, setAlbums] = useState([]);
  const [value, setValue] = useState(null);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    GalleryService.getAlbums().then(
      (response) => {
        if (response.data && response.data) {
          const albumsPerYear = response.data.reduce((albumsPerYear, album) => {
            const year = album.date.split("-")[0];
            if (year in albumsPerYear) {
              albumsPerYear[year].push(album);
            } else {
              albumsPerYear[year] = [album];
            }
            return albumsPerYear;
          }, {});
          setAlbums(albumsPerYear);
        } else {
          setAlbums([]);
        }
      },
      () => {
        setValue(null);
      }
    );
  }, [setValue]);

  return (
    <Fragment>
      {AuthService.isAdmin() && (
        <Link to={"/gallery/album/add"}>
          <Button text={t("Lägg till album")}></Button>
        </Link>
      )}
      {Object.keys(albums)
        .sort()
        .reverse()
        .map((year) => {
          return (
            <div key={{ year }}>
              <h1>{year}</h1>
              {albums[year].map((album) => {
                return (
                  <Link key={album._id} to={"/gallery/album/" + album._id}>
                    <h2>
                      {album.name} - {dayjs(album.date).format("DD.MM.YYYY")}
                    </h2>
                    {album.coverPhoto && (
                      <img src={album.coverPhoto.thumbnail}></img>
                    )}
                  </Link>
                );
              })}
            </div>
          );
        })}
    </Fragment>
  );
};

export default Gallery;
