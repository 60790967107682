import axios from "axios";

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host;
        window.location.replace(baseUrl + "/login");
      }
      return Promise.reject(error);
    }
  );

  if (user && user.accessToken) {
    // for Node.js Express back-end
    return { "x-access-token": user.accessToken };
  } else {
    return {};
  }
}
